import * as PIXI from 'pixi.js';

import { SlotId } from '../../config';
import { Events } from '../../global.d';

import TypedEmitter from './typedEmitter';

export * from './textStyles';
export const eventManager = new PIXI.utils.EventEmitter() as unknown as TypedEmitter<Events>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(window as unknown as any).eventManager = eventManager; // Trick to proxy eventManager to cheats file

export enum SlotMachineState {
  IDLE,
  SPIN,
  STOP,
  JINGLE,
  WINNING,
  CHAIN,
}

export type GameViewObjectPriorityType =
  | 'BASE_OBJECTS_CONTAINER'
  | 'SLOT_ANIMATION'
  | 'GAME_TITLE'
  | 'MINI_PAY_TABLE'
  | 'COINS_ANIMATION'
  | 'WIN_LABEL'
  | 'WIN_COUNT_UP'
  | 'MESSAGE_BANNER'
  | 'BUY_FEATURE_BUTTON'
  | 'BUY_FEATURE_BACKDROP'
  | 'BUY_FEATURE_POPUP'
  | 'BUY_FEATURE_POPUP_CONFIRM';

export const GameViewObjectPriorities: Record<GameViewObjectPriorityType, number> = {
  BASE_OBJECTS_CONTAINER: 1,
  SLOT_ANIMATION: 2,
  GAME_TITLE: 3,
  MINI_PAY_TABLE: 4,
  COINS_ANIMATION: 5,
  WIN_LABEL: 6,
  WIN_COUNT_UP: 7,
  MESSAGE_BANNER: 8,
  BUY_FEATURE_BUTTON: 9,
  BUY_FEATURE_BACKDROP: 10,
  BUY_FEATURE_POPUP: 11,
  BUY_FEATURE_POPUP_CONFIRM: 12,
};

// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = false;
export const APPLICATION_FPS = 60;

// ANNOUNCE ANIMATION
export const ANNOUNCE_ANIMATION_DURATION = 100;

// WIN SETTINGS
export const WIN_ANIMATION_DELAY_TIME_DURATION = 2_000;
export const WIN_CHECK_TIME_DURATION_ON_FREESPIN = 1_000;
export const BIG_WIN_CHECK_TIME_DURATION_ON_FREESPIN = 3_000;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.2;
export const BASE_WIN_COUNT_UP_MULTIPLIER = 0.03;

// BIG WIN SETTINGS
export const BIG_WIN_TITLE = 'BIG WIN!';
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_TITLE_SCALE = 1.3;
export const BIG_WIN_ZOOM_TITLE_DURATION = 500;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.03;
export const BIG_WIN_END_DURATION = 1000;

// MEGA WIN SETTINGS
export const MEGA_WIN_TITLE = 'MEGA WIN!';
export const MEGA_WIN_AMOUNT_LIMIT = 50;
export const MEGA_WIN_TITLE_SCALE = 1.4;
export const MEGA_WIN_ZOOM_TITLE_DURATION = 550;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// GREAT WIN SETTINGS
export const GREAT_WIN_TITLE = 'GREAT WIN!';
export const GREAT_WIN_AMOUNT_LIMIT = 100;
export const GREAT_WIN_TITLE_SCALE = 1.5;
export const GREAT_WIN_ZOOM_TITLE_DURATION = 600;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2;

// EPIC WIN SETTINGS
export const EPIC_WIN_TITLE = 'EPIC WIN!';
export const EPIC_WIN_TITLE_SCALE = 1.6;
export const EPIC_WIN_ZOOM_TITLE_DURATION = 650;
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5;

// WIN SLOT ANIMATION SETTINGS
export const WIN_SLOT_ANIMATION_SCALE = 1.15;
export const WIN_SLOT_ANIMATION_DURATION = 600;
export const TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT = 1;
export const SPRITE_ANIMATION_FPS = 30;

export const REELS_AMOUNT = 8;
//export const SLOTS_PER_REEL_AMOUNT = 5;

export const BASE_GAME_SLOTS_PER_REEL_AMOUNT = 4;
export const FREE_SPINS_SLOTS_PER_REEL_AMOUNT = 5;

export const SLOT_WIDTH = 250;
export const SLOT_HEIGHT = 250;
export const SLOT_WIDTH_MARGIN_X = 0;
export const SLOT_HEIGHT_MARGIN_Y = 0;
export const REEL_WIDTH = SLOT_WIDTH + SLOT_WIDTH_MARGIN_X;
export const REEL_HEIGHT = SLOT_HEIGHT + SLOT_HEIGHT_MARGIN_Y;
export const MINIMUM_SPIN_SLOTS_AMOUNT = 140;

export const SLOT_SCALE = 1.0;
export const SHOW_ALL_LINES_ON_WIN = true;
export const SLOTS_CONTAINER_WIDTH = 1920 - 430 * 2;
export const SLOTS_CONTAINER_HEIGHT = 1024;
export const SHOW_LINES_NUMBER_CONTAINER = false;
export const LINES_NUMBER_CONTAINER_WIDTH = 30;

export const GAME_CONTAINER_WIDTH = 1920 - 430 * 2;
export const GAME_CONTAINER_HEIGHT = 1024;

// ANTICIPATION SETTINGS
export const ANTICIPATION_ENABLE = true;
export const ANTICIPATION_SYMBOLS_ID = [SlotId.SC];
export const ANTICIPATION_START_SYMBOLS_AMOUNT = [];
export const ANTICIPATION_SYMBOLS_AMOUNT_BASE_GAME = [2];
export const ANTICIPATION_SYMBOLS_AMOUNT_FREE_SPINS_MODE = [1];
export const ANTICIPATION_DURATION = 3000;
export const ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT = 15;
export const ANTICIPATION_SLOTS_TINT = 0xaaaaaa;
export const ANTICIPATION_LONG_SPIN_REEL = 3;

// SAFE AREA SETTINGS
export const SAFE_AREA_LANDSCAPE_WIDTH_MARGIN = 300;
export const SAFE_AREA_LANDSCAPE_WIDTH = GAME_CONTAINER_WIDTH + SAFE_AREA_LANDSCAPE_WIDTH_MARGIN;
export const SAFE_AREA_LANDSCAPE_PIVOT_X = -SAFE_AREA_LANDSCAPE_WIDTH_MARGIN / 2;
export const SAFE_AREA_LANDSCAPE_HEIGHT_MARGIN = 1024 - GAME_CONTAINER_HEIGHT;
export const SAFE_AREA_LANDSCAPE_HEIGHT = GAME_CONTAINER_HEIGHT + SAFE_AREA_LANDSCAPE_HEIGHT_MARGIN;
export const SAFE_AREA_LANDSCAPE_PIVOT_Y = 0;

export const SAFE_AREA_PORTRAIT_WIDTH_MARGIN = 50;
export const SAFE_AREA_PORTRAIT_WIDTH = GAME_CONTAINER_WIDTH + SAFE_AREA_PORTRAIT_WIDTH_MARGIN;
export const SAFE_AREA_PORTRAIT_PIVOT_X = -SAFE_AREA_PORTRAIT_WIDTH_MARGIN / 2;
export const SAFE_AREA_PORTRAIT_HEIGHT = 2000;
export const SAFE_AREA_PORTRAIT_PIVOT_Y = -400;

// REELS SETTINGS
export const BASE_SPIN_TIME = 2000;
export const TURBO_SPIN_TIME = 1000;
export const ADDITIONAL_SPIN_TIME_PER_REEL = 300;
export const TURBO_ADDITIONAL_SPIN_TIME_PER_REEL = 100;
export const TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL = 25;
export const SPIN_REEL_ANIMATION_DELAY_PER_REEL = 250;
export const BASE_REEL_BLUR_VALUE = 18;
export const TURBO_REEL_BLUR_VALUE = 24;
export enum ReelState {
  IDLE,
  STARTING,
  ROLLING,
  ENDING,
}
// NUMBER FORMAT
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;
// SPIN ANIMATION SETTINGS
export const INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP = 50;
export const BASE_REEL_STARTING_DURATION = 500;
export const BASE_REEL_ROLLING_DURATION = 1250;
export const BASE_REEL_ENDING_DURATION = 250;
export const FAKE_ROLLING_DURATION = 35_000;
export const FAKE_ROLLING_SLOTS = 400;
export const TURBO_REEL_STARTING_DURATION = 250;
export const TURBO_REEL_ROLLING_DURATION = 500;
export const TURBO_REEL_ENDING_DURATION = 250;
export const FORCE_STOP_SPIN_ANIMATION_DURATION = 250;
export const FORCE_STOP_SPIN_PER_EACH_DURATION = 50;
export const REEL_STARTING_SLOTS_AMOUNT = 3;
export const REEL_ENDING_SLOTS_AMOUNT = 3;
export const BASE_REEL_STARTING_FORMULA = (t: number): number => 3 * t * t * (1.4 * t - 0.4);
export const BASE_REEL_ROLLING_FORMULA = (t: number): number => -0.592691 + 1.77055 * t;
export const BASE_REEL_ENDING_FORMULA = (t: number): number => (t - 1) * (t - 1) * ((0.5 + 1) * (t - 1) + 0.5) + 1;
export const ANTICIPATION_REEL_ROLLING_FORMULA = (t: number): number => -0.6884 + 2.19771 * t;
export const ANTICIPATION_REEL_ENDING_FORMULA = (t: number): number => 0.5 + 0.5 * t;

// GAME TITLE LOGO
export const GAME_TITLE_LOGO_LANDSCAPE_X_POS = -300;
export const GAME_TITLE_LOGO_LANDSCAPE_Y_POS = 120;
export const GAME_TITLE_LOGO_PORTRAIT_X_POS = GAME_TITLE_LOGO_LANDSCAPE_X_POS;
export const GAME_TITLE_LOGO_PORTRAIT_Y_POS = -500;

// TINT CONTAINER SETTINGS
export const TINT_CONTAINER_WIDTH = SLOTS_CONTAINER_WIDTH;
export const TINT_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const TINT_BACKGROUND_COLOR = 0x000000;
export const TINT_ALPHA = 0.5;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = SLOT_WIDTH;
export const PAY_TABLE_HEIGHT = SLOT_HEIGHT;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;
export const PAY_TABLE_NUMBER_COLOR = 0xffffff;
export const PAY_TABLE_TEXT_COLOR = 0xf6e87e;

// COINS ANIMATIONS SETTINGS
export const COINS_AMOUNT_PER_SEC = 8;
export const SINGLE_COIN_ANIMATION_DURATION = 2500;

// FREE SPINS
export const FREE_SPINS_FADE_DURATION = 4000;
export const FREE_SPINS_TIME_OUT_RETRIGGER_BANNER = 5000;
export const FREE_SPINS_TIME_OUT_BANNER = 1000;
export const FREE_SPINS_COUNT_UP_END_DELAY = 1500;

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
}

export type BigWinStages = Exclude<WinStages, WinStages.None | WinStages.BaseWin>;

// LINES SETTINGS
type Position = {
  [key: number]: {
    x: number;
    y: number;
  };
};
export const POSITION: Position = {
  0: {
    x: REEL_WIDTH / 2,
    y: REEL_HEIGHT / 2,
  },
  1: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: REEL_HEIGHT / 2,
  },
  2: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: REEL_HEIGHT / 2,
  },
  3: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: REEL_HEIGHT / 2,
  },
  4: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: REEL_HEIGHT / 2,
  },
  5: {
    x: REEL_WIDTH / 2,
    y: REEL_HEIGHT + REEL_HEIGHT / 2,
  },
  6: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: REEL_HEIGHT + REEL_HEIGHT / 2,
  },
  7: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: REEL_HEIGHT + REEL_HEIGHT / 2,
  },
  8: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: REEL_HEIGHT + REEL_HEIGHT / 2,
  },
  9: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: REEL_HEIGHT + REEL_HEIGHT / 2,
  },

  10: {
    x: REEL_WIDTH / 2,
    y: REEL_HEIGHT * 2 + REEL_HEIGHT / 2,
  },
  11: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: REEL_HEIGHT * 2 + REEL_HEIGHT / 2,
  },
  12: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: REEL_HEIGHT * 2 + REEL_HEIGHT / 2,
  },
  13: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: REEL_HEIGHT * 2 + REEL_HEIGHT / 2,
  },
  14: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: REEL_HEIGHT * 2 + REEL_HEIGHT / 2,
  },
  // add
  15: {
    x: REEL_WIDTH / 2,
    y: REEL_HEIGHT * 3 + REEL_HEIGHT / 2,
  },
  16: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: REEL_HEIGHT * 3 + REEL_HEIGHT / 2,
  },
  17: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: REEL_HEIGHT * 3 + REEL_HEIGHT / 2,
  },
  18: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: REEL_HEIGHT * 3 + REEL_HEIGHT / 2,
  },
  19: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: REEL_HEIGHT * 3 + REEL_HEIGHT / 2,
  },
};

// LINE NUMBERS CONTAINER SETTINGS
export const LINES_NUMBER_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 5;
export const RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 0;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS = [
  { id: 0, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 1, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 35 },
  { id: 2, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 70 },
  { id: 3, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 105 },
  { id: 4, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 140 },
  { id: 5, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 175 },
  { id: 6, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 210 },
  { id: 7, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 245 },
  { id: 8, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 280 },
  { id: 9, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 315 },
  { id: 10, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 350 },
  { id: 11, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 385 },
];
export const RIGHT_LINES_NUMBERS_CONTAINER = [
  { id: 12, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 13, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 32 },
  { id: 14, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 64 },
  { id: 15, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 96 },
  { id: 16, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 128 },
  { id: 17, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 160 },
  { id: 18, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 192 },
  { id: 19, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 224 },
  { id: 20, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 256 },
  { id: 21, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 288 },
  { id: 22, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 320 },
  { id: 23, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 352 },
  { id: 24, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 384 },
];

// BUY FEATURE BTN
export const FEATURE_BTN_CONTAINER_POSITION_Y = 250;
export const FEATURE_BTN_CONTAINER_POSITION_X = -140;
export const FEATURE_BTN_LANDSCAPE_WIDTH = 172;
export const FEATURE_BTN_LANDSCAPE_HEIGHT = 137;

export const FEATURE_BTN_PORTRAIT_WIDTH = 250;
export const FEATURE_BTN_PORTRAIT_HEIGHT = 170;

// BUY FEATURE POPUP
export const FEATURE_POPUP_TEXT_ALPHA = 0.25;

export const FEATURE_POPUP_TITLE_POSITION_Y = -330;
export const FEATURE_POPUP_TITLE_POSITION_X = 0;
export const FEATURE_POPUP_CANCEL_BTN_WIDTH = 282;
export const FEATURE_POPUP_CANCEL_BTN_HEIGHT = 109;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_Y = 270;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X - 200;
export const FEATURE_POPUP_OK_BTN_WIDTH = 282;
export const FEATURE_POPUP_OK_BTN_HEIGHT = 109;
export const FEATURE_POPUP_OK_BTN_POSITION_Y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
export const FEATURE_POPUP_OK_BTN_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X + 200;

export const FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_X = 0;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y = FEATURE_POPUP_TITLE_POSITION_Y + 150;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_X = 0;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y = FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y + 90;

export const FEATURE_POPUP_INPUT_WIDTH = 454;
export const FEATURE_POPUP_INPUT_HEIGHT = 114;
export const FEATURE_POPUP_INPUT_POSITION_Y = FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y + 180;
export const FEATURE_POPUP_INPUT_POSITION_X = 0;

export const FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y = FEATURE_POPUP_INPUT_POSITION_Y - 90;
export const FEATURE_POPUP_AMOUNT_TEXT_POSITION_X = 0;

export const FEATURE_POPUP_BET_VALUE_POSITION_Y = FEATURE_POPUP_INPUT_POSITION_Y;
export const FEATURE_POPUP_BET_VALUE_POSITION_X = 0;

export const FEATURE_POPUP_MINUS_BTN_WIDTH = 113;
export const FEATURE_POPUP_MINUS_BTN_HEIGHT = 120;
export const FEATURE_POPUP_MINUS_BTN_POSITION_Y = FEATURE_POPUP_INPUT_POSITION_Y;
export const FEATURE_POPUP_MINUS_BTN_POSITION_X = -FEATURE_POPUP_INPUT_WIDTH / 2 - FEATURE_POPUP_MINUS_BTN_WIDTH / 2;

export const FEATURE_POPUP_PLUS_BTN_POSITION_Y = FEATURE_POPUP_MINUS_BTN_POSITION_Y;
export const FEATURE_POPUP_PLUS_BTN_POSITION_X = FEATURE_POPUP_INPUT_WIDTH / 2 + FEATURE_POPUP_MINUS_BTN_WIDTH / 2;

export const FEATURE_POPUP_MIN = 1;
export const FEATURE_POPUP_MAX = 40;

// BUY FEATURE CONFIRM
export const FEATURE_POPUP_CONFIRM_POPUP_BG_WIDTH = 1173;
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y = -220;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y + 150;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y + 100;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_WIDTH = 282;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y + 120;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X = -FEATURE_POPUP_CONFIRM_CANCEL_BTN_WIDTH / 2 - 50;
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y;
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X = FEATURE_POPUP_CONFIRM_CANCEL_BTN_WIDTH / 2 + 50;

// RESPONSE WAITING LOGO
export const RESPONSE_WAITING_LOGO_DISPLAY_START_TIME = 5_000;
