import { Container, Text } from 'pixi.js';

import { Variables } from '../../../config';
import { SpineInterface } from '../../../config/spine.generated';
import { Game } from '../../../game';
import Animation from '../../animations/animation';
import { StrictSpine } from '../../components/spine';
import { REELS_AMOUNT } from '../../config';
import { ConvertIndex } from '../slot';

const animationSlotIndexes: ConvertIndex = {
  [0]: 5,
  [1]: 4,
  [2]: 3,
  [3]: 2,
  [4]: 1,
};

const textStyle = {
  fontFamily: Variables.FONT_FAMILY,
  fill: ['#FFFC00', '#CE7A23', '#FFFC00', '#CE7A23', '#FFFC00', '#CE7A23'],
  fillGradientStops: [0.1, 0.2, 0.4, 0.6, 0.8],
  fillGradientType: 0,
  stroke: '#151515',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 3,
  dropShadowDistance: 3,
  letterSpacing: 2,
  miterLimit: 4,
  //fontSize: 36,
};

const slotIndexPer: { y: number; fontSize: number }[] = [
  { y: -160 - 280, fontSize: 42 }, // outer
  { y: -160 - 200, fontSize: 38 },
  { y: -160 - 130, fontSize: 36 },
  { y: -160 - 70, fontSize: 34 },
  { y: -160, fontSize: 32 }, // inner
];

type MultiplierSymbolAnimationNames = SpineInterface['symbol_multiplier']['animations'];

export class MultiplierSymbol extends Container {
  private spine: StrictSpine<'symbol_multiplier'>;

  private multiplierText: Text;

  public multiplier = 0;

  constructor(multiplierValue: number, reelId: number, slotIndex: number) {
    super();
    this.multiplier = multiplierValue;
    this.multiplierText = new Text(`x${multiplierValue}`, {
      ...textStyle,
      fontSize: slotIndexPer[slotIndex]!.fontSize,
    });
    this.multiplierText.angle = 360 - (360 / REELS_AMOUNT) * reelId;
    this.multiplierText.anchor.set(0.5);
    this.multiplierText.position.set(0, slotIndexPer[slotIndex]!.y);

    const spine = Game.getInstance().maker.spine('symbol_multiplier');
    this.spine = spine;

    this.addChild(spine);
    this.addChild(this.multiplierText);
  }

  public getSpineAnimation(slotIndex: number, animationAbbv: 'stop' | 'in' | 'lost' | 'win'): Animation {
    return this.spine.getAnimation(
      0,
      `step${this.getAnimationSlotIndex(slotIndex)}_${animationAbbv}` as MultiplierSymbolAnimationNames,
    );
  }

  private getAnimationSlotIndex = (slotIndex: number) => {
    // The numbers in the animation name and data are reversed
    return animationSlotIndexes[slotIndex];
  };

  public changePosition(slotIndex: number) {
    this.multiplierText.position.set(0, slotIndexPer[slotIndex]!.y);
    this.multiplierText.style = {
      ...textStyle,
      fontSize: slotIndexPer[slotIndex]!.fontSize,
    };
  }
}
